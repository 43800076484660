<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <!-- Listeleme Kartı -->
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title mb-0">Sorgu Listesi</h4>
        <b-button variant="primary" @click="showAddModal()"
          >Yeni Sorgu Ekle</b-button
        >
      </div>
      <b-table
        striped
        hover
        :items="queryList"
        :fields="tableFields"
        responsive
      >
        <template #cell(branchId)="data">
          {{ getBrancgTypeCode(data.item.branchId) }}
        </template>
        <template #cell(actions)="data">
          <!-- <b-button size="sm" variant="warning" @click="editQuery(data.item)">
            Düzenle
          </b-button> -->
          <b-button
            size="sm"
            variant="danger"
            @click="deleteQuery(data.item.id)"
          >
            Sil
          </b-button>
        </template>
      </b-table>
    </b-card>

    <!-- Yeni Sorgu Ekle Modal -->
    <b-modal title="Yeni Sorgu Ekle" hide-footer v-model="addQueryModal">
      <b-form>
        <b-form-group label="Sorgu Anahtarı">
          <b-form-input
            v-model="query.key"
            placeholder="Sorgu Anahtarı"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="SQL Sorgusu">
          <b-form-textarea
            v-model="query.sqlQuery"
            placeholder="SQL Sorgusu"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Yedek Sorgu">
          <b-form-textarea
            v-model="query.backupQuery"
            placeholder="Yedek Sorgu"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Açıklama">
          <b-form-input
            v-model="query.description"
            placeholder="Açıklama"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Modül">
          <b-form-select id="Şube Seçin" v-model="query.branchId" required>
            <option
              v-for="branch in branchOptions"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.branchName }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-button variant="primary" @click="queryAddSend">Kaydet</b-button>
        <b-button class="float-right" variant="secondary" @click="closeModal"
          >İptal</b-button
        >
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ToastificationContent,
  },
  data() {
    return {
      show: false,
      addQueryModal: false,
      query: {
        key: "",
        sqlQuery: "",
        backupQuery: "",
        description: "",
        branchId: null,
      },
      branchOptions: [],
      queryList: [], // Sorgu listesini burada tutun
      tableFields: [
        { key: "key", label: "Sorgu Anahtarı", sortable: true },
        { key: "sqlQuery", label: "SQL Sorgusu", sortable: false },
        { key: "description", label: "Açıklama", sortable: true },
        { key: "branchId", label: "Şube", sortable: true },
        { key: "actions", label: "İşlem" },
      ],
    };
  },
  created() {
    this.getBranch();
    this.getQuery();
  },
  methods: {
    showAddModal() {
      this.addQueryModal = true;
      this.query = {
        key: "",
        sqlQuery: "",
        backupQuery: "",
        description: "",
        branchId: null,
      };
    },
    async queryAddSend() {
      // Yeni sorgu ekleme işlemi
      this.show = true;

      this.$http
        .post("query", this.query)
        .then((response) => {
          this.show = false;
          this.queryList.push({ ...this.query });
          this.addQueryModal = false;
          this.getQuery();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
      //  // Örnek olarak listeye ekliyoruz
    },

    deleteQuery(id) {
      this.$bvModal
        .msgBoxConfirm("Sorguyu Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "danger",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`query/${id}`)
              .then((response) => {
                this.queryList = this.queryList.filter((i) => i.id !== id);
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Başarılı`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Silme İşlemi Başarılı..`,
                  },
                });
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    //
    getBrancgTypeCode(id) {
      const type = this.branchOptions.find((s) => s.id === id);
      return type ? type.branchName : "Bilinmeyen Tip";
    },
    async getQuery() {
      this.$http
        .get("query")
        .then((response) => {
          if (response.status == 200) {
            this.show = false;
            this.queryList = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getBranch() {
      this.$http
        .get("branch/getbranchs")
        .then((response) => {
          if (response.status == 200) {
            this.show = false;
            this.branchOptions = response.data;
            console.log(this.branchOptions);
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    closeModal() {
      this.addQueryModal = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  font-weight: bold;
}
</style>
